import Chart from 'chart.js';
import chartColors from './chartColors.js';

export default (datasets, categoryLabels) => {
    const color = Chart.helpers.color;
    return {
        type: 'radar',
        data: {
            labels: categoryLabels,
            datasets,
        },
        options: {
            animation: false,
            animate: false,
            startAngle: 15,
            layout: {
                padding: 10,
            },
            legend: {
                display: false,
            },
            tooltips: {
                displayColors: false,
                backgroundColor: chartColors.darkGrey,
                callbacks: {
                    label: function(tooltipItem, data) {
                        return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.yLabel}`;
                    },
                    title: function(tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            },
            title: false,
            radarColors: {
                backgroundColor: chartColors.grey,
                strokeColor: chartColors.darkGrey,
            },
            scale: {
                angleLines: {
                    display: false
                },
                ticks: {
                    animate: false,
                    display: false,
                    beginAtZero: true,
                    maxTicksLimit: 6,
                    min: 0,
                    max: 100,
                    showLabelBackdrop: false,
                    fontColor: chartColors.black,
                    fontStyle: 'bold',
                    fontSize: 18,
                    fontFamily: 'Open Sans, sans-serif'
                },
                pointLabels: {
                    fontColor: color(chartColors.black).alpha(0.4).rgbString(),
                    fontSize: 18,
                    fontFamily: 'Open Sans, sans-serif'
                },
                gridLines: {
                    color: chartColors.mediumGrey,
                }
            },
        }
    }
}
