const chartColorSettings = require('./chartColorSettings.json');

export default {
      // Default colors.
      grey: 'rgb(242, 242, 242)',
      mediumGrey: 'rgb(206, 206, 206)',
      darkGrey: 'rgb(112, 112, 112)',
      black: 'rgb(29, 29, 27)',
      white: 'rgb(255, 255, 255)',

      // List of colors we use for the ambition profile.
      ...chartColorSettings
}
