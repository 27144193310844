import Chart from 'chart.js';
import radarBackground from './radarBackground.js';
import legend from './legend.js';
import defaultConfig from './defaultConfig.js';
import chartColors from './chartColors.js';

export default (props) => {
const color = Chart.helpers.color;

const dataSetOptions = {
hidden: false,
borderColor: color(chartColors.white).alpha(0).rgbString(),
pointBorderColor: color(chartColors.white).alpha(0).rgbString(),
pointBackgroundColor: color(chartColors.white).alpha(0).rgbString(),
pointHitRadius: 3,
pointRadius: 3,
};

const dataSets = JSON.parse(props.dataSets).map((dataset) => {
return {
...dataSetOptions,
...dataset,
backgroundColor: color(chartColors[dataset.backgroundColor]).alpha(0.6).rgbString(),
}
})

const categoryLabels = JSON.parse(props.categoryLabels);

const config = defaultConfig(dataSets, categoryLabels);

Chart.pluginService.register(radarBackground);
Chart.pluginService.register(legend);
window.radarChart = new Chart(props.$elm, config);
}
