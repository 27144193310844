export default {
   beforeDraw(chart) {
      let i, offset, position;
      let me = chart.scale;
      let ctx = me.ctx;

      ctx.fillStyle = chart.options.radarColors.backgroundColor;
      ctx.strokeStyle = chart.options.radarColors.strokeColor;
      ctx.lineWidth = 4;
      ctx.beginPath();
      for (i = chart.data.labels.length - 1; i >= 0; i--) {
          offset = me.getDistanceFromCenterForValue(me.max);
          position = me.getPointPosition(i, offset);
          ctx.lineTo(position.x, position.y);
      }
      ctx.closePath();
      ctx.stroke();
      ctx.fill();
  }
}
