export default {
    afterDatasetsDraw(chart) {
      let me = chart.scale;
      let ctx = me.ctx;
      let opts = me.options;
      let tickOpts = opts.ticks;
      let helpers = Chart.helpers;
      let core_defaults = Chart.defaults;

      let tickFont = helpers.options._parseFont(tickOpts);
      let tickFontColor = helpers.valueOrDefault(tickOpts.fontColor, core_defaults.global.defaultFontColor);
      let padding = 6;
      let offset;

      ctx.font = tickFont.string;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      helpers.each(me.ticks, function(label, index) {
          if (index === 0 && !tickOpts.reverse) {
              return;
          }

          offset = me.getDistanceFromCenterForValue(me.ticksAsNumbers[index]);

          ctx.fillStyle = tickFontColor;
          ctx.fillText(label, me.xCenter, me.yCenter - offset - padding);
      });
  }
}
