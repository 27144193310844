require('./bootstrap');

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import Cropper from 'cropperjs'
import TomSelect from 'tom-select'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/anchor'
import radar from './components/charts/radar.js';
import route from 'ziggy-js';

const modules = {
    radar,
}

window.load = (module, config = {}) => modules[module](config);

// Ziggy
window.route = route;

// Alpine
window.Alpine = Alpine
Alpine.plugin(focus)
Alpine.start()


// TOM SELECT on all select elements
var config = {};
const selects = document.getElementsByTagName("select");
for (const select in selects) {
    if (typeof selects[select].id == 'string' && selects[select].id != '') {
        new TomSelect('#' + selects[select].id, config);
    }
}
// END TOM SELECT


// IMAGE CROP
window.onChangeCropImage = function (e) {

    var files = e.target.files;
    var done = function (url) {
        e.target.value = '';
        image.src = url;

        var cropper = new Cropper(image, {
            aspectRatio: 1,
            viewMode: 1,
        });

        document.getElementById('cropButton').addEventListener('click', function (e) {

            var canvas;

            if (cropper) {

                canvas = cropper.getCroppedCanvas({
                    width: 160,
                    height: 160,
                });

                document.getElementById('croppedImage').value = canvas.toDataURL();
                // document.getElementById('imageForm').submit();
            }

        });
    };

    var reader;
    var file;

    if (files && files.length > 0) {
        file = files[0];
        if (URL) {
            done(URL.createObjectURL(file));
        } else if (FileReader) {
            reader = new FileReader();
            reader.onload = function (e) {
                done(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }
};

document.addEventListener("DOMContentLoaded", function () {
    tinymce.init({
        selector: `textarea.tinymce`,
        plugins: 'lists',
        toolbar: 'undo redo | styleselect | bold italic | numlist bullist',
        menubar: false,
        width: '100%',
        height: 300
    });

    tinymce.init({
        selector: `textarea.tinymceBig`,
        plugins: 'lists, link, image, anchor',
        toolbar: 'undo redo | styleselect | bold italic | numlist bullist',
        menubar: true,
        width: '100%',
        height: 500,
    });
});
